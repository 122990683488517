<template>
  <div>
    <FurusatoCouponUseTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    ></FurusatoCouponUseTable>
  </div>
</template>
<script>
  import FurusatoCouponUseTable from "../../_common/List/FurusatoCouponUseTable";

  export default {
    data() {
      return {
        tableName: this.$t('users.user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.FURUSATO_COUPON_USE,
          },
        },
        actions: {
          name: "furusato_coupon_use",
          exportEntries: true,
          routerLink: "furusato-coupon-use/",
        },
        search: {
          searchAll: false,
          reset: true,
        },
        filters: {
          member_by_name_multi_like: {
            name: 'member_by_name',
            type: 'multi_like',
            label: this.$t('furusato_coupon_use.name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            options: [],
            boundaryClass: 'col-md-3 col-sm-12'
          },
          member_by_email_multi_like: {
            name: 'member_by_email',
            type: 'multi_like',
            label: this.$t('furusato_coupon_use.email'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          ticket_tour_multi_like: {
            name: 'ticket_tour',
            type: 'multi_like',
            label: this.$t('furusato_coupon_use.ticket_tour'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          full_city_id_equal: {
            name: 'full_city_id',
            type: 'equal',
            label: this.$t('furusato_product.furusato_city_id'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          booking_id_multi_like: {
            name: 'booking_id',
            type: 'multi_like',
            label: this.$t('furusato_coupon_use.booking_id'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          publish_at_equal: {
            name: 'publish_at',
            type: 'equal',
            label: this.$t('furusato_coupon.reception_date'),
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          input_hidden: {
            name: 'hidden',
            type: 'equal',
            label: " ",
            uiType: 'input_hidden',
            boundaryClass: 'space-date',
          },
          expire_at_equal: {
            name: 'expire_at',
            type: 'equal',
            label: " ",
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          created_at_equal: {
            name: 'created_at',
            type: 'equal',
            label: this.$t('furusato_coupon_use.date_use'),
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          input_hidden_2: {
            name: 'hidden',
            type: 'equal',
            label: " ",
            uiType: 'input_hidden',
            boundaryClass: 'space-date',
          },
          created_end_equal: {
            name: 'created_end',
            type: 'equal',
            label: " ",
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
        },
        columns: [
          {name: 'member_by_name', label: this.$t('furusato_coupon_use.name'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.member ? furusato_coupon_use.member.sei + this.$consts.INPUT.SPACE_FULL_SIZE + furusato_coupon_use.member.mei : "";
            }},
          {name: 'member_by_email', label: this.$t('furusato_coupon_use.email'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.member ? furusato_coupon_use.member.email : "";
            }},
          {name: 'ticket_tour', label: this.$t('furusato_coupon_use.ticket_tour_header'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.coupons ? furusato_coupon_use.coupons.code : "";
            }},
          {name: 'expired_at', label: this.$t('furusato_coupon_use.valid_time_header'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.coupons ?
                moment(furusato_coupon_use.coupons.publish_at).format('YYYY年MM月DD日 HH:mm') + '~ </br>' + moment(furusato_coupon_use.coupons.expire_at).format('YYYY年MM月DD日 HH:mm') : "";
            }},
          {name: 'booking_id', label: this.$t('furusato_coupon_use.booking_id_table'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.booking_id ? furusato_coupon_use.booking_id : "";
            }},
          {name: 'data_use', label: this.$t('furusato_coupon_use.date_use'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return furusato_coupon_use.used_at != null ? moment(furusato_coupon_use.used_at).format('YYYY年MM月DD日 HH:mm') : "";
            }},
          {name: 'created_at', label: this.$t('furusato_coupon_use.add_coupon_header'), class: 'min-w-100 text-center',
            computedHtml: furusato_coupon_use => {
              return moment(furusato_coupon_use.created_at).format('YYYY年MM月DD日 HH:mm');
            }}
        ],
      }
    },
    components: {
      FurusatoCouponUseTable,
    },
    mounted() {
      this.$request.get(this.$consts.API.ADMIN.UI_HELPER_FURUSATO_COUPON_USE).then(res => {
        if (!res.hasErrors()) {
          this.filters.member_by_name_multi_like.options = _.cloneDeep(res.data.member_by_name);
          this.filters.member_by_email_multi_like.options = _.cloneDeep(res.data.member_by_email);
          this.filters.full_city_id_equal.options = _.cloneDeep(res.data.full_city);
          this.filters.ticket_tour_multi_like.options = _.cloneDeep(res.data.code_ticket_tour);
          this.filters.booking_id_multi_like.options = _.cloneDeep(res.data.booking);
        }
      })
    },
    methods: {
      getFurusatoCouponUse() {
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_COUPON_USE).then(res => {
          if(!res.hasErrors()) {
            this.filters = _.cloneDeep(this.filters)
          }
        })
      },
      onResetAllSearch() {
        this.getFurusatoCouponUse();
      },
      onSearch() {
        this.getFurusatoCouponUse();
      },
    }
  }
</script>
